<template>
  <div v-loading="loading" class="page">
    <div class="main-wrap flex">
      <!-- left-wrap start -->
      <div class="left-wrap flex flex-dc">
        <div class="menu-list-wrap flex flex-dc">
          <div class="tooltip-wrap">
            <el-input placeholder="请输入内容" v-model="search" clearable class="input-with-select">
              <i slot="prepend" class="el-icon-search"></i>
              <div slot="append" class="flex flex-ac">
                <i class="el-icon-sort pointer mr10"></i>
                <i class="el-icon-s-operation pointer"></i>
              </div>
            </el-input>
          </div>
          <div class="list-wrap flex flex-dc flex-1">
            <div class="list-group flex"
                 v-for="company in companyList" :key="company.id"
                 :class=" {active: activeCompanyId && activeCompanyId === company.id}"
                 @click="activeCompanyId = company.id">
              <div class="img-wrap">
                <img v-if="company.logo" :src="company.logo">
                <img v-else src="@/assets/images/logo.png">
              </div>
              <div class="info-wrap flex-1 flex flex-dc">
                <div class="title">{{ company.name }}</div>
                <div class="text">狀態: {{ company.status || '--' }}</div>
                <div class="text">進度: {{ company.progress }} / 9</div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-wrap flex flex-ac flex-jc" @click="activeCompanyId=null">
          <i class="el-icon-circle-plus-outline"></i>
        </div>
      </div>
      <!-- left-wrap end -->

      <!-- right-wrap start -->
      <div class="right-wrap flex-1">
        <div class="card-wrap flex flex-dc">
          <div class="card-group flex flex-dc">
            <div class="card-header flex flex-jb">
              <div class="header-left flex flex-ac">
                <img v-if="activeCompanyId && activeCompany.logo" :src="activeCompany.logo"/>
                <img v-else src="@/assets/images/logo.png"/>
                <span class="company-name">{{
                    activeCompanyId && activeCompany.name ? activeCompany.name : '--'
                  }}</span>
              </div>

              <div class="header-right flex flex-1 flex-je">
                <div class="area-wrap flex flex-dc">
                  <span class="label">預計利潤/m</span>
                  <span class="value" @click="projectRevenueDialog = true">{{
                      activeCompanyId && activeCompany.projectedRevenue ? (activeCompany.projectedRevenue + " " + activeCompany.currency) : "--"
                    }}</span>
                </div>
                <div class="area-wrap flex flex-dc">
                  <span class="label">預計貨量</span>
                  <span class="value" @click="projectRevenueDialog = true">{{
                      activeCompanyId && activeCompany.projectedShipmentVolume ? (activeCompany.projectedShipmentVolume + " " + activeCompany.volumeUnit) : "--"
                    }}</span>
                </div>
                <div class="area-wrap flex flex-dc">
                  <span class="label">預計合作月份</span>
                  <span class="value" @click="projectRevenueDialog = true">{{
                      activeCompanyId && activeCompany.expectedDate ? activeCompany.expectedDate.substring(0, 7) : "--"
                    }}</span>
                </div>
                <div class="area-wrap flex flex-ac" style="padding-right:10%">
                  <div>
                    <img
                        v-if="activeCompanyId && activeCompany.customerManager && activeCompany.customerManager.faceUrl"
                        :src="activeCompany.customerManager.faceUrl" class="user">
                    <img v-else src="@/assets/images/logo.png" class="user">
                  </div>
                  <div class="flex flex-dc">
                    <span class="label">業務經理</span>
                    <span class="value text-primary" @click="projectRevenueDialog = true">{{
                        activeCompanyId && activeCompany.customerManager && activeCompany.customerManager.nickname ? activeCompany.customerManager.nickname : '--'
                      }}</span>
                  </div>
                  <div class="star-wrap" @click="toggleFavorite">
                    <i style="color: gold" :class="{'el-icon-star-on' : activeCompanyId && activeCompany.isFavorite,
                     'el-icon-star-off': !activeCompanyId || !activeCompany.isFavorite}"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-content flex">
              <div class="flex flex-ac">
                <div class="label flex flex-ac fw" style="font-size:calc(0.6vw + 6px)">
                  <i class="el-icon-time mr5" style="font-size:calc(1vw + 10px)"></i>
                  <span class="mr10">狀態:</span>
                </div>
                <div>
                  <el-dropdown trigger="click" @command="companyStatusChange">
                    <span class="el-dropdown-link">
                      {{ activeCompanyId && activeCompany.status ? activeCompany.status : '請選擇' }}<i
                        class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="A">A</el-dropdown-item>
                      <el-dropdown-item command="B">B</el-dropdown-item>
                      <el-dropdown-item command="C">C</el-dropdown-item>
                      <el-dropdown-item command="D">D</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>

              <div class="list-wrap flex-1 flex flex-ac flex-jc">
                <div class="list-item flex flex-ac flex-jc"
                     :class="{'active': activeCompany?.companyProgress?.isResearch}"
                     @click="toggleProgress(1)">調研
                </div>
                <div class="list-item flex flex-ac flex-jc"
                     :class="{'active': activeCompany?.companyProgress?.isEmail}"
                     @click="toggleProgress(2)">郵件
                </div>
                <div class="list-item flex flex-ac flex-jc"
                     :class="{'active': activeCompany?.companyProgress?.isPhone}"
                     @click="toggleProgress(3)">電話
                </div>
                <div class="list-item flex flex-ac flex-jc"
                     :class="{'active': activeCompany?.companyProgress?.isSocial}"
                     @click="toggleProgress(4)">社群
                </div>
                <div class="list-item flex flex-ac flex-jc"
                     :class="{'active': activeCompany?.companyProgress?.isVideo}"
                     @click="toggleProgress(5)">影片
                </div>
                <div class="list-item flex flex-ac flex-jc"
                     :class="{'active': activeCompany?.companyProgress?.isVisit}"
                     @click="toggleProgress(6)">拜訪
                </div>
                <div class="list-item flex flex-ac flex-jc"
                     :class="{'active': activeCompany?.companyProgress?.isInviteVisit}"
                     @click="toggleProgress(7)">參訪
                </div>
                <div class="list-item flex flex-ac flex-jc"
                     :class="{'active': activeCompany?.companyProgress?.isSolution}"
                     @click="toggleProgress(8)">解決<br>方案
                </div>
                <div class="list-item flex flex-ac flex-jc"
                     :class="{'active': activeCompany?.companyProgress?.isDeal}"
                     @click="toggleProgress(9)">成交
                </div>
              </div>
            </div>
          </div>

          <div class="flex-1 flex flex-ww" style="overflow-y: auto;">
            <div class="flex-1 flex flex-dc">
              <div class="card-group flex flex-dc">
                <div class="card-header flex flex-ac flex-jb is-border">
                  <div class="title flex flex-ac">客戶基本資料</div>
                  <div class="tooltip flex flex-ac">
                    <el-upload
                        ref="logoUpload"
                        v-show="cardStatus._1.isEdit"
                        :action="uploadUrl"
                        :data="templateUploadData"
                        :headers="uploadHeaders"
                        :on-success="handleUploadLogoSuccess"
                        :on-error="handleUploadLogoError"
                        :show-file-list="false"
                        :limit="1"
                        :file-list="logoList">
                      <i class="el-icon-upload"></i>
                    </el-upload>
                    <i class="el-icon-edit" :class="{'text-success': cardStatus._1.isEdit}"
                       @click="cardStatus._1.isEdit = !cardStatus._1.isEdit"></i>
                  </div>
                </div>
                <div class="card-content">
                  <div class="form-wrap global-form-wrap">
                    <div class="form-group flex flex-ac">
                      <div class="form-label">*公司ID</div>
                      <div class="form-control flex-1">
                        {{ activeCompanyId && activeCompany.code ? activeCompany.code : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">*名稱(中文)</div>
                      <div class="form-control flex-1">
                        <el-input v-model="activeCompany.name" placeholder="请输入内容" size="small"
                                  :disabled="!cardStatus._1.isEdit"></el-input>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">*名稱(英文)</div>
                      <div class="form-control flex-1">
                        <el-input v-model="activeCompany.nameEn" placeholder="请输入内容" size="small"
                                  :disabled="!cardStatus._1.isEdit"></el-input>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">*統編</div>
                      <div class="form-control flex-1">
                        <el-input v-model="activeCompany.businessNo" placeholder="请输入内容" size="small"
                                  :disabled="!cardStatus._1.isEdit"></el-input>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">*公司地址</div>
                      <div class="form-control flex-1">
                        <el-input v-model="activeCompany.address" placeholder="请输入内容" size="small"
                                  :disabled="!cardStatus._1.isEdit"></el-input>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">電話-國碼</div>
                      <div class="form-control flex-1">
                        <el-input v-model="activeCompany.phoneAreaCode" placeholder="请输入内容" size="small"
                                  :disabled="!cardStatus._1.isEdit"></el-input>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">電話</div>
                      <div class="form-control flex-1">
                        <el-input v-model="activeCompany.phone" placeholder="请输入内容" size="small"
                                  :disabled="!cardStatus._1.isEdit"></el-input>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">分公司位置</div>
                      <div class="form-control flex-1">
                        <el-input v-model="activeCompany.branchOfficeAddress" placeholder="请输入内容" size="small"
                                  :disabled="!cardStatus._1.isEdit"></el-input>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">官網</div>
                      <div class="form-control flex-1">
                        <el-input v-model="activeCompany.homePageUrl" placeholder="请输入内容" size="small"
                                  :disabled="!cardStatus._1.isEdit"></el-input>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac flex-je" v-show="cardStatus._1.isEdit">
                      <el-button type="success" size="small" @click="saveBase">Save</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-group flex flex-dc">
                <div class="card-header flex flex-ac flex-jb is-border">
                  <div class="title flex flex-ac">客戶產業資訊</div>
                  <div class="tooltip flex flex-ac">
                    <i class="el-icon-edit" :class="{'text-success': cardStatus._2.isEdit}"
                       @click="cardStatus._2.isEdit = !cardStatus._2.isEdit"></i>
                  </div>
                </div>
                <div class="card-content">
                  <div class="form-wrap global-form-wrap">
                    <div class="form-group flex flex-ac">
                      <div class="form-label">*產業</div>
                      <div class="form-control flex-1">
                        <el-select v-model="activeCompany.domainIds" multiple filterable placeholder="请选择"
                                   size="small" style="width: 100%;" :disabled="!cardStatus._2.isEdit">
                          <el-option v-for="item in domainList" :key="item.id" :label="item.name" :value="item.id"/>
                        </el-select>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">*產品</div>
                      <div class="form-control flex-1">
                        <el-select v-model="activeCompany.productionTypeIds" multiple filterable placeholder="请选择"
                                   size="small" style="width: 100%;" :disabled="!cardStatus._2.isEdit">
                          <el-option v-for="item in productionTypeList" :key="item.id" :label="item.name"
                                     :value="item.id"/>
                        </el-select>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">品牌類型</div>
                      <div class="form-control flex-1">
                        <el-input v-model="activeCompany.brandTypeIds" placeholder="请输入内容" size="small"
                                  :disabled="!cardStatus._1.isEdit"></el-input>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">代理品牌</div>
                      <div class="form-control flex-1">
                        <el-select v-model="activeCompany.brands" multiple filterable allow-create default-first-option
                                   placeholder="请选择" size="small" style="width: 100%;"
                                   :disabled="!cardStatus._2.isEdit">
                        </el-select>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label" style="white-space:pre-wrap">{{"代理商\n(for原廠)"}}</div>
                      <div class="form-control flex-1">
                        <el-select v-model="activeCompany.supplierIds" multiple filterable placeholder="请选择"
                                   size="small" style="width: 100%;" :disabled="!cardStatus._2.isEdit">
                          <el-option v-for="item in companyListAll" :key="item.id" :label="item.name"
                                     :value="item.id"/>
                        </el-select>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac flex-je">
                      <div class="form-label">關聯關係</div>
                      <div class="form-control flex-1">
                        <el-button type="success" size="small" @click="openRelated()">Open</el-button>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">Remark</div>
                      <div class="form-control flex-1">
                        <el-input v-model="activeCompany.remarks" placeholder="请输入内容" size="small"
                                  :disabled="!cardStatus._2.isEdit"></el-input>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac flex-je" v-show="cardStatus._2.isEdit">
                      <el-button type="success" size="small" @click="updateDomain">Save</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-group flex flex-dc">
                <div class="card-header flex flex-ac flex-jb is-border">
                  <div class="title flex flex-ac">
                    <span>聯繫人資訊</span>
                    <i class="tag flex flex-ac flex-jc">{{ activeCompany ? activeCompany.contactCount : '0' }}</i>
                  </div>
                </div>
                <div class="card-content">
                  <div class="form-wrap global-form-wrap">
                    <div class="form-group flex flex-ac">
                      <div class="form-label">人名</div>
                      <div class="form-control flex-1">
                        {{ activeCompany?.contact ? activeCompany.contact.name : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">職稱</div>
                      <div class="form-control flex-1">
                        {{ activeCompany?.contact ? activeCompany.contact.title : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">權力類別</div>

                      <div class="form-control flex-1">
                        <el-select v-model="activeCompany.contact" placeholder="请选择" size="small"
                                   disabled style="width:100%">
                          <el-option label="引薦者" value="REFERRAL"></el-option>
                          <el-option label="影響者" value="INFLUENCE"></el-option>
                          <el-option label="決策者" value="DECISION"></el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">責任類別</div>
                      <div class="form-control flex-1">
                        <el-select v-model="activeCompany.contact" placeholder="请选择" size="small"
                                   disabled style="width:100%">
                          <el-option label="操作者" value="OPERATOR"></el-option>
                          <el-option label="主管" value="SUPERVISOR"></el-option>
                          <el-option label="經營" value="BUSINESS"></el-option>
                          <el-option label="經理" value="MANGER"></el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac flex-je">
                      <el-button :disabled="!activeCompanyId" type="text" @click="connectUserDrawer = true">More
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-group flex flex-dc">
                <div class="card-header flex flex-ac flex-jb is-border">
                  <div class="title flex flex-ac">客戶來源</div>
                  <div class="tooltip flex flex-ac">
                    <i class="el-icon-edit" :class="{'text-success': cardStatus._3.isEdit}"
                       @click="cardStatus._3.isEdit = !cardStatus._3.isEdit"></i>
                  </div>
                </div>
                <div class="card-content">
                  <div class="form-wrap global-form-wrap">
                    <div class="form-group flex flex-ac">
                      <div class="form-label">*來源方式</div>
                      <div class="form-control flex-1">
                        <el-select v-model="activeCompany.acquisitionMethod" filterable allow-create
                                   default-first-option placeholder="请选择" size="small" style="width: 100%;"
                                   :disabled="!cardStatus._3.isEdit">
                          <el-option value="公司目前客戶群"></el-option>
                          <el-option value="外部資料收集"></el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">*來源類型</div>
                      <div class="form-control flex-1">
                        <el-select v-model="activeCompany.acquisitionType" filterable allow-create default-first-option
                                   placeholder="请选择" size="small" style="width: 100%;"
                                   :disabled="!cardStatus._3.isEdit">
                          <el-option value="客戶"></el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">*來源客戶</div>
                      <div class="form-control flex-1">
                        <el-select v-model="activeCompany.acquisitionCustomer" filterable allow-create
                                   default-first-option placeholder="请选择" size="small" style="width: 100%;"
                                   :disabled="!cardStatus._3.isEdit">
                          <el-option v-for="customer in customerList" :key="'acquisitionCustomer_' + customer.id"
                                     :value="customer.abbr"></el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">*取得日期</div>
                      <div class="form-control flex-1">
                        <el-date-picker
                            v-model="activeCompany.acquireAt"
                            type="date"
                            size="small"
                            placeholder="请选择"
                            style="width: 100%;"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :disabled="!cardStatus._3.isEdit">
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">*切入方式</div>
                      <div class="form-control flex-1">
                        <el-input
                            type="textarea"
                            :rows="3"
                            placeholder="请输入内容"
                            v-model="activeCompany.acquireMemo"
                            :disabled="!cardStatus._3.isEdit">
                        </el-input>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac flex-je" v-show="cardStatus._3.isEdit">
                      <el-button type="success" size="small" @click="updateAcquisition">Save</el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-1 flex flex-dc">
              <div class="card-group flex flex-dc">
                <div class="card-header flex flex-ac flex-jb is-border">
                  <div class="title flex flex-ac">
                    <span>行銷紀錄</span>
                    <i class="tag flex flex-ac flex-jc">{{
                        activeCompany?.salesLogCount ? activeCompany.salesLogCount : '0'
                      }}</i>
                  </div>
                </div>
                <div class="card-content">
                  <div class="form-wrap global-form-wrap">
                    <div class="form-group flex flex-ac">
                      <div class="form-label">最後更新時間</div>
                      <div class="form-control flex-1">
                        {{ activeCompany?.salesLog ? activeCompany.salesLog.updatedAt : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">接收人</div>
                      <div class="form-control flex-1">
                        {{ activeCompany?.salesLog ? activeCompany.salesLog.contactNameList : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">發送標題</div>
                      <div class="form-control flex-1">
                        {{ activeCompany?.salesLog ? activeCompany.salesLog.topic : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac flex-jb">
                      <div>
                        <span v-if="activeCompany?.salesLog && activeCompany.salesLog.fileList">
                          <el-tag v-for="file in activeCompany.salesLog.fileList" :key="file.id"
                                  class="flex flex-ac flex-jc pointer mr5" effect="plain">
                            <img src="@/assets/images/pic-2.png" width="20px" class="mr10"
                                 style="vertical-align: middle">
                            <a :href="file.uri" :download="file.name + '.' + file.category" target="_blank">{{
                                file.name
                              }}</a>
                          </el-tag>
                        </span>

                        <span v-if="activeCompany?.salesLog && activeCompany.salesLog.videoList">
                          <el-tag v-for="file in activeCompany.salesLog.videoList" :key="file.id"
                                  class="flex flex-ac flex-jc pointer mr5" effect="plain">
                            <img src="@/assets/images/pic-1.png" width="20px" class="mr10"
                                 style="vertical-align: middle">
                            <a :href="file.uri" :download="file.name + '.' + file.category" target="_blank">{{
                                file.name
                              }}</a>
                          </el-tag>
                        </span>
                      </div>
                      <el-button :disabled="!activeCompanyId" type="text" @click="marketingRecordDrawer = true">More
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-group flex flex-dc">
                <div class="card-header flex flex-ac flex-jb is-border">
                  <div class="title flex flex-ac">
                    <span>電訪紀錄</span>
                    <i class="tag flex flex-ac flex-jc">{{
                        activeCompany?.phoneLogCount ? activeCompany.phoneLogCount : '0'
                      }}</i>
                  </div>
                </div>
                <div class="card-content">
                  <div class="form-wrap global-form-wrap">
                    <div class="form-group flex flex-ac">
                      <div class="form-label">最後更新時間</div>
                      <div class="form-control flex-1">
                        {{ activeCompany?.phoneLog ? activeCompany.phoneLog.updatedAt : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">電訪人</div>
                      <div class="form-control flex-1">
                        {{ activeCompany?.phoneLog ? activeCompany.phoneLog.contactNameList : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">電訪主題</div>
                      <div class="form-control flex-1">
                        {{ activeCompany?.phoneLog ? activeCompany.phoneLog.topic : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac flex-je">
                      <el-button :disabled="!activeCompanyId" type="text" @click="phoneRecordDrawer = true">More
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-group flex flex-dc">
                <div class="card-header flex flex-ac flex-jb is-border">
                  <div class="title flex flex-ac">
                    <span>拜訪紀錄</span>
                    <i class="tag flex flex-ac flex-jc">{{
                        activeCompany?.visitLogCount ? activeCompany.visitLogCount : '0'
                      }}</i>
                  </div>
                </div>
                <div class="card-content">
                  <div class="form-wrap global-form-wrap">
                    <div class="form-group flex flex-ac">
                      <div class="form-label">最後更新時間</div>
                      <div class="form-control flex-1">
                        {{ activeCompany?.visitLog ? activeCompany.visitLog.updatedAt : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">拜訪人</div>
                      <div class="form-control flex-1">
                        {{ activeCompany?.visitLog ? activeCompany.visitLog.contactNameList : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">拜訪主題</div>
                      <div class="form-control flex-1">
                        {{ activeCompany?.visitLog ? activeCompany.visitLog.topic : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac flex-je">
                      <el-button :disabled="!activeCompanyId" type="text" @click="visitRecordDrawer = true">More
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-group flex flex-dc">
                <div class="card-header flex flex-ac flex-jb is-border">
                  <div class="title flex flex-ac">
                    <span>參訪紀錄</span>
                    <i class="tag flex flex-ac flex-jc">{{
                        activeCompany?.inviteLogCount ? activeCompany.inviteLogCount : '0'
                      }}</i>
                  </div>
                </div>
                <div class="card-content">
                  <div class="form-wrap global-form-wrap">
                    <div class="form-group flex flex-ac">
                      <div class="form-label">最後更新時間</div>
                      <div class="form-control flex-1">
                        {{ activeCompany?.inviteLog ? activeCompany.inviteLog.updatedAt : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">參訪人</div>
                      <div class="form-control flex-1">
                        {{ activeCompany?.inviteLog ? activeCompany.inviteLog.contactNameList : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">參訪主題</div>
                      <div class="form-control flex-1">
                        {{ activeCompany?.inviteLog ? activeCompany.inviteLog.topic : '--' }}
                      </div>
                    </div>
                    <div class="form-group flex flex-ac flex-je">
                      <el-button :disabled="!activeCompanyId" type="text" @click="interviewRecordDrawer = true">More
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-group flex flex-dc">
                <div class="card-header flex flex-ac flex-jb is-border">
                  <div class="title flex flex-ac">
                    <span>生意機會</span>
                  </div>
                </div>
                <div class="card-content">
                  <div class="form-wrap global-form-wrap">
                    <div class="form-group flex flex-ac">
                      <div class="form-label">生意類型</div>
                      <div class="form-control flex-1">
                        <el-tag v-if="activeCompany?.haveHubChance" class="mr10">倉庫</el-tag>
                        <el-tag v-if="activeCompany?.haveTransportChance">運輸</el-tag>
                        <span v-if="!activeCompany?.haveHubChance && !activeCompany?.haveTransportChance">--</span>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">倉庫型態</div>
                      <div class="form-control flex-1">
                        <el-tag v-if="activeCompany?.haveServiceType_101" class="mr10">自有倉</el-tag>
                        <el-tag v-if="activeCompany?.haveServiceType_102">外包倉</el-tag>
                        <span
                            v-if="!activeCompany?.haveServiceType_101 && !activeCompany?.haveServiceType_102">--</span>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac">
                      <div class="form-label">運輸型態</div>
                      <div class="form-control flex-1">
                        <el-tag v-if="activeCompany?.haveServiceType_200" class="mr10">空運</el-tag>
                        <span v-if="!activeCompany?.haveServiceType_200">--</span>
                      </div>
                    </div>
                    <div class="form-group flex flex-ac flex-je">
                      <el-button :disabled="!activeCompanyId" type="text" @click="businessOpportunitiesDrawer = true">
                        More
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- right-wrap end -->

      <!-- toggle-wrap start -->
      <div class="toggle-wrap flex flex-dc" :class="{'show': isHistoryToggle}">
        <div class="toggle-hide flex flex-dc flex-ac" v-if="!isHistoryToggle"
             @click="isHistoryToggle = !isHistoryToggle">
          <i class="icon el-icon-d-arrow-left mb10"></i>
          <span class="label">客戶聯絡歷程</span>
        </div>

        <div class="toggle-show flex flex-dc flex-ac flex-1">
          <div class="toggle-header-wrap flex flex-ac">
            <i class="icon el-icon-place mr10"></i>
            <span class="label mr10">客戶聯絡歷程</span>
            <i class="icon el-icon-d-arrow-right flex-1" style="text-align:right"
               @click="isHistoryToggle = !isHistoryToggle"></i>
          </div>

          <div class="toggle-content-wrap flex-1 flex-dc flex-ac">
            <div v-if="updateLogList?.length" class="text flex flex-ac flex-jc mb15">{{ diffDays(new Date(), updateLogList[0].createdAt) }} days</div>
            <div v-else class="text flex flex-ac flex-jc mb15"> 未更新 </div>
            <div v-for="(log, index) in updateLogList" :key="log.id" class="group-wrap flex flex-dc">
              <div class="group-title flex flex-ac mb10">
                <!--                <div class="pic-wrap mr10 flex flex-ac">-->
                <!--                  <img src="@/assets/images/icon-11.png" width="20px">-->
                <!--                </div>-->
                <div class="date-wrap text-primary fw">
                  {{ log.createdAt }}
                </div>
              </div>
              <div class="group-text flex flex-jb">
                <div>{{ log.nickname }}</div>
                <div>{{ log.message }}</div>
              </div>
              <div v-if="index !== updateLogList.length - 1" :class="{first: index === 0, 'join-wrap': true}">
                <span class="text flex flex-ac flex-jc">
                  {{ diffDays(log.createdAt, updateLogList[index + 1].createdAt) }} day
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- toggle-wrap end -->

      <!-- 预计利润修改 -->
      <div>
        <el-dialog
            :visible.sync="projectRevenueDialog"
            :append-to-body="true"
            width="30%">
          <div class="form-wrap global-form-wrap">
            <div class="form-group flex flex-ac">
              <div class="form-label">業務經理</div>
              <div class="form-control flex-1">
                <el-select v-model="activeCompany.customerManagerId" filterable default-first-option remote
                           :remote-method="searchUser" placeholder="请选择" size="small" style="width: 100%;"
                           @change="customerManagerChange">
                  <el-option v-for="item in userList" :key="item.id" :label="item.nickname" :value="item.id"/>
                </el-select>
              </div>
            </div>
            <div class="form-group flex flex-ac">
              <div class="form-label">預計營收/m</div>
              <div class="form-control flex-1">
                <el-input v-model="activeCompany.projectedRevenue" placeholder="请输入内容" size="small"></el-input>
              </div>
            </div>
            <div class="form-group flex flex-ac">
              <div class="form-label">幣別</div>
              <div class="form-control flex-1">
                <el-select v-model="activeCompany.currencyId" filterable default-first-option placeholder="请选择"
                           size="small" style="width: 100%;"
                           @change="currencyChange">
                  <el-option v-for="item in currencyList" :key="item.id" :label="item.code" :value="item.id"/>
                </el-select>
              </div>
            </div>
            <div class="form-group flex flex-ac">
              <div class="form-label">預計貨量</div>
              <div class="form-control flex-1">
                <el-input v-model="activeCompany.projectedShipmentVolume" placeholder="请输入内容"
                          size="small"></el-input>
              </div>
            </div>
            <div class="form-group flex flex-ac">
              <div class="form-label">單位</div>
              <div class="form-control flex-1">
                <el-select v-model="activeCompany.volumeUnit" filterable allow-create default-first-option
                           placeholder="请选择" size="small" style="width: 100%;">
                  <el-option value="CTN"></el-option>
                  <el-option value="PLT"></el-option>
                </el-select>
              </div>
            </div>
            <div class="form-group flex flex-ac">
              <div class="form-label">預計合作月份</div>
              <div class="form-control flex-1">
                <el-date-picker
                    v-model="activeCompany.expectedDate"
                    type="month"
                    size="small"
                    placeholder="请选择"
                    style="width: 100%;"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
              </div>
            </div>
            <div class="form-group flex flex-ac">
              <div class="form-label">最新進度</div>
              <div class="form-control flex-1">
                <el-input v-model="activeCompany.memo" placeholder="请输入内容" size="small" type="textarea"
                          :rows="3"></el-input>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="projectRevenueDialog = false">取 消</el-button>
            <el-button type="primary" @click="updateProjectRevenue">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>

    <!-- 關聯關係明細 -->
    <div style="text-align: center">
      <el-dialog
          title="關聯關係详情"
          :append-to-body="true"
          :close-on-click-modal="false"
          width="800px"
          :visible.sync="isOpenRelatedDialog"
          style="text-align: center"
      >
        <div>
          <div  style="position:relative;display: flex;">
          <div>
          <el-upload
              ref="uploadRelatedFileRef"
              class="upload"
              :action="companyRelatedUploadUrl"
              :data="companyRelatedData"
              :headers="uploadHeaders"
              :show-file-list="false"
              :limit="1"
              :on-success="uploadRelatedFileSuccess"
              :on-error="uploadRelatedError"
              style="margin-right: 20px; text-align: left">
            <el-button size="small" type="primary">上傳模板檔案</el-button>
          </el-upload>
          </div>
          <div  style="margin-right: 20px; text-align: left">
            <el-link size="small" type="primary" target="_blank" :href="templateFileUrl" download="templateFile">下載當前模板</el-link>
          </div>
          </div>
          <div style="position:relative;display: flex;margin-top: 20px">
            <div style="flex: 1">
              <el-table
                  :data="supplierList"
                  :cell-style="{'text-align':'center'}"
                  :header-cell-style="{ color: '#395c9c', textAlign: 'center' }"
              >
                <el-table-column label="供應商">
                  <template slot-scope="scope">
                    <a :href=scope.row.url :download="scope.row.name" target='_blank' v-if="scope.row.url" style="color: #338dcd"> {{ scope.row.name }} </a>
                    <a target='_blank' v-if="!scope.row.url"> {{ scope.row.name }} </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div style="flex: 1">
              <el-table
                  :data="customList"
                  :cell-style="{'text-align':'center'}"
                  :header-cell-style="{ color: '#395c9c', textAlign: 'center' }"
              >
                <el-table-column label="客戶">
                  <template slot-scope="scope">
                    <a :href=scope.row.url :download="scope.row.name" target='_blank' v-if="scope.row.url" style="color: #338dcd"> {{ scope.row.name }} </a>
                    <a target='_blank' v-if="!scope.row.url"> {{ scope.row.name }} </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div style="flex: 1">
              <el-table
                  :data="competitorList"
                  :cell-style="{'text-align':'center'}"
                  :header-cell-style="{ color: '#395c9c', textAlign: 'center' }"
              >
                <el-table-column label="競爭者">
                  <template slot-scope="scope">
                    <a :href=scope.row.url :download="scope.row.name" target='_blank' v-if="scope.row.url" style="color: #338dcd"> {{ scope.row.name }} </a>
                    <a target='_blank' v-if="!scope.row.url"> {{ scope.row.name }} </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div style="flex: 1">
              <el-table
                  :data="reinvestmentList"
                  :cell-style="{'text-align':'center'}"
                  :header-cell-style="{ color: '#395c9c', textAlign: 'center' }"
              >
                <el-table-column label="轉投資">
                  <template slot-scope="scope">
                    <a :href=scope.row.url :download="scope.row.name" target='_blank' v-if="scope.row.url" style="color: #338dcd"> {{ scope.row.name }} </a>
                    <a target='_blank' v-if="!scope.row.url"> {{ scope.row.name }} </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div style="flex: 1">
              <el-table
                  :data="investedList"
                  :cell-style="{'text-align':'center'}"
                  :header-cell-style="{ color: '#395c9c', textAlign: 'center' }"
              >
                <el-table-column label="被投資">
                  <template slot-scope="scope">
                    <a :href=scope.row.url :download="scope.row.name" target='_blank' v-if="scope.row.url" style="color: #338dcd"> {{ scope.row.name }} </a>
                    <a target='_blank' v-if="!scope.row.url"> {{ scope.row.name }} </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div style="flex: 1">
              <el-table
                  :data="allianceList"
                  :cell-style="{'text-align':'center'}"
                  :header-cell-style="{ color: '#395c9c', textAlign: 'center' }"
              >
                <el-table-column label="策略聯盟">
                  <template slot-scope="scope">
                    <a :href=scope.row.url :download="scope.row.name" target='_blank' v-if="scope.row.url" style="color: #338dcd"> {{ scope.row.name }} </a>
                    <a target='_blank' v-if="!scope.row.url"> {{ scope.row.name }} </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
  <!--          <el-pagination-->
  <!--              class="flex flex-ac flex-je mt1r"-->
  <!--              background-->
  <!--              :hide-on-single-page="true"-->
  <!--              :current-page.sync="currentPageRelated"-->
  <!--              :page-sizes="[10, 20, 50, 100]"-->
  <!--              :page-size.sync="pageSizeRelated"-->
  <!--              layout="total, sizes, prev, pager, next, jumper"-->
  <!--              :total="listTotalRelated"-->
  <!--              @size-change="getCompanyRelatedList()"-->
  <!--              @current-change="getCompanyRelatedList()"-->
  <!--          />-->
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- 聯係人資訊明細 -->
    <el-drawer
        direction="rtl"
        size="1000px"
        ref="connectUserDrawer"
        :destroy-on-close="true"
        :append-to-body="true"
        :visible.sync="connectUserDrawer"
        :wrapperClosable="false">
      <ConnectUser @closeDrawer="closeDrawer($event)" :companyId="activeCompanyId"/>
    </el-drawer>

    <!-- 行銷記錄明細 -->
    <el-drawer
        direction="rtl"
        size="1000px"
        ref="marketingRecordDrawer"
        :destroy-on-close="true"
        :append-to-body="true"
        :visible.sync="marketingRecordDrawer"
        :wrapperClosable="false">
      <MarketingRecord @closeDrawer="closeDrawer($event)" :companyId="activeCompanyId"/>
    </el-drawer>

    <!-- 電訪記錄 -->
    <el-drawer
        direction="rtl"
        size="1000px"
        ref="phoneRecordDrawer"
        :destroy-on-close="true"
        :append-to-body="true"
        :visible.sync="phoneRecordDrawer"
        :wrapperClosable="false">
      <PhoneRecord @closeDrawer="closeDrawer($event)" :companyId="activeCompanyId"/>
    </el-drawer>

    <!-- 拜訪記錄 -->
    <el-drawer
        direction="rtl"
        size="1000px"
        ref="visitRecordDrawer"
        :destroy-on-close="true"
        :append-to-body="true"
        :visible.sync="visitRecordDrawer"
        :wrapperClosable="false">
      <VisitRecord @closeDrawer="closeDrawer($event)" :companyId="activeCompanyId"/>
    </el-drawer>

    <!-- 參訪記錄 -->
    <el-drawer
        direction="rtl"
        size="1000px"
        ref="interviewRecordDrawer"
        :destroy-on-close="true"
        :append-to-body="true"
        :visible.sync="interviewRecordDrawer"
        :wrapperClosable="false">
      <InterviewRecord @closeDrawer="closeDrawer($event)" :companyId="activeCompanyId"/>
    </el-drawer>

    <!-- 生意機會 -->
    <el-drawer
        direction="rtl"
        size="1200px"
        ref="businessOpportunitiesDrawer"
        :destroy-on-close="true"
        :append-to-body="true"
        :visible.sync="businessOpportunitiesDrawer"
        :wrapperClosable="false">
      <BusinessOpportunities @closeDrawer="closeDrawer($event)" :companyId="activeCompanyId"/>
    </el-drawer>

  </div>
</template>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';

.page ::v-deep {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #f5f5f5;

  .main-wrap {
    height: 100%;
    overflow: hidden;

    .left-wrap {
      width: calc(16vw);
      min-width: 200px;
      max-width: 400px;
      border-right: 1px solid #999;
      padding: 15px;

      .menu-list-wrap {
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 5px 5px rgba(#000, 0.2);
        overflow: hidden;

        .tooltip-wrap {
          border-bottom: 1px solid #999;

          .el-input-group__prepend,
          .el-input__inner,
          .el-input-group__append {
            background-color: #fff;
            border: none;

            i {
              font-size: calc(0.7vw + 7px);
            }
          }
        }

        .list-wrap {
          overflow-y: auto;

          .list-group {
            border-bottom: 1px solid #999;
            padding: 15px;
            cursor: pointer;

            &:last-child {
              border: none;
            }

            &.active {
              background-color: rgba($--color-primary, 0.1);
            }

            img {
              width: calc(1vw + 10px);
            }

            .info-wrap {
              margin-left: 10px;
              font-size: calc(0.5vw + 5px);

              .title {
                color: $--color-primary;
                font-weight: bold;
              }
            }
          }
        }
      }

      .btn-wrap {
        padding: 20px 0;
        background-color: $--color-primary;
        color: #fff;
        box-shadow: 0 5px 5px rgba(#000, 0.2);
        margin-top: 15px;
        border-radius: 5px;

        i {
          font-size: calc(1vw + 10px);
          cursor: pointer;
        }
      }
    }

    .right-wrap {
      margin-left: 15px;
      padding: 15px 0;

      .card-wrap {
        height: 100%;
        overflow: hidden;

        .card-group {
          box-shadow: 0 5px 5px rgba(#000, 0.2);
          margin: 0 15px 15px 0;
          background-color: #fff;
          padding: 15px;
          border-radius: 5px;

          .card-header {

            &.is-border {
              border-bottom: 1px solid #999;
              padding-bottom: 5px;
            }

            i {
              font-size: calc(0.8vw + 8px);
              cursor: pointer;
              margin-right: 10px;
              color: #555;
            }

            .title {
              color: $--color-primary;
              font-size: calc(0.6vw + 6px);
              font-weight: bold;

              .tag {
                width: calc(0.8vw + 8px);
                height: calc(0.8vw + 8px);
                color: #fff;
                background-color: $--color-primary;
                border-radius: 100%;
                margin-left: 10px;
                font-size: calc(0.5vw + 5px);
              }
            }

            .header-left {
              img {
                width: calc(1.5vw + 15px);
              }

              .company-name {
                font-size: calc(0.7vw + 7px);
                font-weight: bold;
                color: $--color-primary;
                margin-left: 10px;
              }
            }

            .header-right {

              .area-wrap {
                border-right: 1px solid #ccc;
                padding: 0 5%;
                position: relative;
                font-size: calc(0.5vw + 5px);

                &:last-child {
                  border: none;
                }

                .user {
                  width: calc(1.2vw + 12px);
                  margin-right: 10px;
                }

                .label {
                  color: #999;
                }

                .value {
                  font-weight: bold;
                }
              }

              .star-wrap {
                position: absolute;
                right: 0;
                top: 5px;

                i {
                  font-size: calc(1vw + 10px);
                  cursor: pointer;
                }
              }
            }
          }

          .card-content {
            margin-top: 10px;

            .list-wrap {
              .list-item {
                margin-left: 2%;
                width: calc(2.2vw + 22px);
                height: calc(2.2vw + 22px);
                border-radius: 100%;
                border: 4px solid #999;
                color: #999;
                font-size: calc(0.6vw + 5px);
                font-weight: bold;
                cursor: pointer;

                &.active {
                  background-color: $--color-primary;
                  color: #fff;
                  border-color: $--color-primary;
                  box-shadow: 0 0 5px #555;
                  text-shadow: 0 0 15px #fff;
                }
              }
            }
          }
        }
      }
    }

    .toggle-wrap {
      width: 30px;
      border-left: 1px solid #999;
      transition: all 0.3s ease-in-out;

      &.show {
        width: 300px;

        .toggle-show {
          opacity: 1;
          transition: opacity 0.8s ease-in-out;
        }
      }

      .toggle-hide {
        padding: 10px;
        cursor: pointer;
      }

      .toggle-show {
        opacity: 0;
        overflow: hidden;
      }

      .icon {
        font-size: calc(0.7vw + 7px);
        text-shadow: 0 0 5px #999;
        font-weight: bold;
        color: #444;
        cursor: pointer;
      }

      .label {
        font-size: calc(0.5vw + 5px);
        text-shadow: 0 0 5px #999;
        font-weight: bold;
        color: #444;
      }

      .toggle-header-wrap {
        width: calc(100% - 20px);
        border-bottom: 1px solid #999;
        padding: 10px 0;
        margin: 0 10px 10px 10px;
      }

      .toggle-content-wrap {
        width: 100%;
        overflow-y: auto;
        padding: 10px;

        .text {
          font-size: calc(0.5vw + 5px);
        }

        .group-wrap {
          margin-bottom: 40px;
          box-shadow: 0 5px 5px rgba(#000, 0.2);
          border-radius: 5px;
          background-color: #fff;
          padding: 10px;
          font-size: calc(0.5vw + 5px);
          position: relative;

          .join-wrap {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 40px;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 50%;
              width: 4px;
              margin-left: -2px;
              background-color: $--color-primary;
            }

            &.first:before {
              top: 3px;
            }

            &.first:after {
              content: '';
              position: absolute;
              top: -9px;
              left: 50%;
              margin-left: -10px;
              border-width: 10px;
              border-style: solid;
              border-color: transparent transparent $--color-primary transparent;
            }

            .text {
              width: 50%;
              height: 100%;
              float: right;
              color: $--color-primary;
              font-size: calc(0.5vw + 5px);
              font-weight: bold;
            }
          }
        }
      }
    }

  }
}
</style>

<script>
import ConnectUser from './components/connect-user'
import MarketingRecord from './components/marketing-record'
import PhoneRecord from './components/phone-record'
import VisitRecord from './components/visit-record'
import InterviewRecord from './components/interview-record'
import BusinessOpportunities from './components/business-opportunities'
import * as companyApi from '@/apis/company'
import * as companyRelatedApi from '@/apis/companyRelated'
import * as basicInfoApi from '@/apis/basic-info'
import * as customerApi from '@/apis/customer'
import * as userApi from '@/apis/user'
import * as updateLogApi from '@/apis/update-log'
import {getToken} from "@/utils/auth";
import {diffDays} from "@/utils/time-utils";

export default {
  components: {ConnectUser, MarketingRecord, PhoneRecord, VisitRecord, InterviewRecord, BusinessOpportunities},
  data() {
    return {
      loading: false,
      category: {
        _1: false,
        _2: false,
        _3: false,
        _4: false,
        _5: false,
        _6: false,
        _7: false,
        _8: false,
        _9: false
      },
      cardStatus: {
        _1: {
          isEdit: false
        },
        _2: {
          isEdit: false
        },
        _3: {
          isEdit: false
        }
      },
      search: '',
      isHistoryToggle: false,
      connectUserDrawer: false,
      marketingRecordDrawer: false,
      phoneRecordDrawer: false,
      visitRecordDrawer: false,
      interviewRecordDrawer: false,
      businessOpportunitiesDrawer: false,
      projectRevenueDialog: false,
      isOpenRelatedDialog: false,
      companyRelatedList: [],
      companyRelatedData: null,
      companyRelatedUploadUrl: '',
      currentPageRelated: 1,
      pageSizeRelated: 10,
      listTotalRelated: 0,
      companyList: [],
      companyListAll: [],
      activeCompanyId: null,
      activeCompany: {},
      domainList: [],
      productionTypeList: [],
      brandTypeList: [],
      customerList: [],
      currencyList: [],
      userList: [],
      uploadUrl: process.env.VUE_APP_BASE_API + '/basic/info/upload',
      templateUploadData: {
        typeId: 49
      },
      uploadHeaders: {
        'S-Token': getToken()
      },
      logoList: [],
      updateLogList: [],
      templateFileUrl: '/template/templateImport.xlsx',
      supplierList: [],
      customList: [],
      competitorList: [],
      reinvestmentList: [],
      investedList: [],
      allianceList: []
    }
  },
  created() {
    this.init();
  },
  watch: {
    activeCompanyId: function (val) {
      if (val) {
        this.loadCompany(val);
      } else {
        this.activeCompany = {};
      }
    },
    search: function () {
      this.getCompanyList()
    }
  },
  /**
   *
   */
  methods: {
    closeDrawer(drawerName) {
      this[drawerName] = false;
      this.$refs[drawerName].closeDrawer();
      this.getList();
    },

    init() {
      if (this.$route.query.id) {
        this.activeCompanyId = this.$route.query.id;
      }
      this.getCompanyList();
      this.loadBasicInfo();
      this.getCompanyListAll();
    },

    /**
     * 获取公司列表
     */
    getCompanyList() {
      this.loading = true;
      companyApi.listSimple(this.search).then(res => {
        this.companyList = res;
        if (this.companyList && this.companyList.length > 0 && !this.activeCompanyId) {
          this.activeCompanyId = this.companyList[0].id;
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    getCompanyListAll() {
      this.loading = true;
      companyApi.listSimple().then(res => {
        this.companyListAll = res;
      }).finally(() => {
        this.loading = false;
      })
    },
    /**
     * 获取公司详情
     * @param id
     */
    loadCompany(id) {
      this.loading = true;
      this.activeCompany = {};
      companyApi.get(id).then((data) => {
        if (data.customerManager) {
          this.userList = [data.customerManager]
        }

        this.activeCompany = data;

        // 所有编辑状态全部关闭
        for (const key in this.cardStatus) {
          this.cardStatus[key].isEdit = false;
        }

        // 生意机会
        if (data.chanceTypeList?.length) {
          this.activeCompany.haveHubChance = data.chanceTypeList.includes(100);
          this.activeCompany.haveTransportChance = data.chanceTypeList.includes(200);
        }
        if (data.serviceTypeList?.length) {
          this.activeCompany.haveServiceType_101 = data.serviceTypeList.includes(101);
          this.activeCompany.haveServiceType_102 = data.serviceTypeList.includes(102);
          this.activeCompany.haveServiceType_200 = data.serviceTypeList.includes(200);
        }

        this.loadUpdateLog(id)
      }).catch(() => {
        this.loading = false;
      })
    },
    /**
     * 加载更新日志
     */
    loadUpdateLog(companyId) {
      this.loading = true;

      updateLogApi.list(companyId).then(res => {
        this.updateLogList = res;
      }).finally(() => {
        this.loading = false;
      })
    },
    /**
     * 公司状态改变
     * @param status
     */
    companyStatusChange(status) {
      if (!this.activeCompanyId) {
        this.$notify.error('請選擇或創建公司');
        return;
      }
      this.loading = true;

      companyApi.updateStatus(this.activeCompanyId, status).then(() => {
        this.activeCompany.status = status;
        this.companyList.forEach(company => {
          if (company.id === this.activeCompanyId) {
            company.status = status;
          }
        })

        this.loadCompany(this.activeCompanyId);
      }).finally(() => {
        this.loading = false;
      });
    },
    /**
     * 反转进度
     * @param progress
     */
    toggleProgress(progress) {
      if (!this.activeCompanyId) {
        this.$notify.error('請選擇或創建公司');
        return;
      }
      this.loading = true;

      companyApi.toggleProgress(this.activeCompanyId, progress).then((data) => {
        this.companyList.forEach(company => {
          if (company.id === this.activeCompanyId) {
            company.progress = data;
          }
        })

        this.loadCompany(this.activeCompanyId);
      }).catch(() => {
        this.loading = false;
      })
    },
    /**
     * 反转收藏
     */
    toggleFavorite() {
      if (!this.activeCompanyId) {
        this.$notify.error('請選擇或創建公司');
        return;
      }
      this.loading = true;

      companyApi.toggleFavorite(this.activeCompanyId).then(() => {
        this.loadCompany(this.activeCompanyId);
      }).catch(() => {
        this.loading = false;
      })
    },
    /**
     * 保存客戶基本資料
     */
    saveBase() {
      this.loading = true;

      if (!this.activeCompanyId) {
        // 新增
        companyApi.add(this.activeCompany).then((data) => {
          this.activeCompanyId = data;
          this.getCompanyList();
          this.loadCompany(this.activeCompanyId);
        }).catch(() => {
          this.loading = false;
        })
      } else {
        // 更新
        companyApi.updateBase(this.activeCompany).then(() => {
          this.loadCompany(this.activeCompanyId);
          this.getCompanyList();
        }).catch(() => {
          this.loading = false;
        })
      }
    },
    /**
     * 更新产业资讯
     */
    updateDomain() {
      if (!this.activeCompanyId) {
        this.$notify.error('請選擇或創建公司');
        return;
      }
      this.loading = true;

      companyApi.updateDomain(this.activeCompany).then(() => {
        this.loadCompany(this.activeCompanyId);
      }).catch(() => {
        this.loading = false;
      })
    },
    /**
     * 更新客户来源
     */
    updateAcquisition() {
      if (!this.activeCompanyId) {
        this.$notify.error('請選擇或創建公司');
        return;
      }
      this.loading = true;

      companyApi.updateAcquisition(this.activeCompany).then(() => {
        this.loadCompany(this.activeCompanyId);
      }).catch(() => {
        this.loading = false;
      })
    },
    /**
     * 更新利润预计和负责人
     */
    updateProjectRevenue() {
      if (!this.activeCompanyId) {
        this.$notify.error('請選擇或創建公司');
        return;
      }
      this.loading = true;

      companyApi.updateProjectRevenue(this.activeCompany).then(() => {
        this.loadCompany(this.activeCompanyId);
        this.projectRevenueDialog = false
      }).catch(() => {
        this.loading = false;
      })
    },
    /**
     * 获取一些基本资料
     */
    loadBasicInfo() {
      basicInfoApi.getBasicInfoList(4).then(res => {
        this.domainList = res;
      })

      basicInfoApi.getBasicInfoList(2).then(res => {
        this.productionTypeList = res;
      })

      basicInfoApi.getBasicInfoList(22).then(res => {
        this.brandTypeList = res;
      })

      basicInfoApi.getCurrencyList().then(res => {
        this.currencyList = res;
      })

      customerApi.getaAllCustomer().then(res => {
        this.customerList = res;
      })
    },
    currencyChange(value) {
      this.currencyList.forEach(item => {
        if (item.id === value) {
          this.activeCompany.currency = item.code;
        }
      })
    },
    customerManagerChange(value) {
      this.userList.forEach(item => {
        if (item.id === value) {
          this.activeCompany.customerManager = item;
        }
      })
    },
    openRelated() {
      console.info('=====' + this. activeCompanyId)
      if (!this.activeCompanyId) {
        this.$notify.error('請選擇或創建公司');
        return;
      }
      this.companyRelatedUploadUrl =  process.env.VUE_APP_BASE_API + '/crm/companyRelated/upload'
      this.companyRelatedData = {'companyId': this.activeCompanyId}
      this.isOpenRelatedDialog = true
      this.getCompanyRelatedList(this.activeCompanyId)
    },
    getCompanyRelatedList(){
      this.supplierList = []
      this.customList = []
      this.competitorList = []
      this.reinvestmentList = []
      this.investedList = []
      this.allianceList = []
      const data ={
        companyId: this.activeCompanyId
        // currentPage: this.currentPageRelated,
        // pageSize: this.pageSizeRelated
      }
      this.loading = true;
      companyRelatedApi.get(data).then(res => {
        this.companyRelatedList = res;
        res.map(item => {
          if (item.type == '供應商'){
            this.supplierList.push(item);
          }
        })
        res.map(item => {
          if (item.type == '客戶'){
            this.customList.push(item);
          }
        })
        res.map(item => {
          if (item.type == '競爭者'){
            this.competitorList.push(item);
          }
        })
        res.map(item => {
          if (item.type == '轉投資'){
            this.reinvestmentList.push(item);
          }
        })
        res.map(item => {
          if (item.type == '被投資'){
            this.investedList.push(item);
          }
        })
        res.map(item => {
          if (item.type == '策略聯盟'){
            this.allianceList.push(item);
          }
        })
      }).finally(() => {
        this.loading = false;
      })
    },
    /**
     * 搜索用户
     */
    searchUser(value) {
      userApi.list(value).then((res) => {
        this.userList = res;
      })
    },
    handleUploadLogoSuccess(res) {
      this.$refs.logoUpload.clearFiles();
      if (res.code !== 0) {
        this.$message({
          message: res.msg || '文件上传失败',
          type: 'error'
        });
        return;
      }

      this.activeCompany.logo = res.data;
    },
    handleUploadLogoError() {
      this.$refs.logoUpload.clearFiles();
    },
    uploadRelatedError() {
      this.$refs.uploadRelatedFileRef.clearFiles();
    },
    handleClose(done) {
      done();
    },

    uploadRelatedFileSuccess(res) {
      if (res.code !== 0) {
        this.$message({
          message: '上傳失敗!',
          type: 'error'
        });
        return;
      }
      this.$message({
        message: '上傳成功!',
        type: 'success'
      });
      this.$refs.uploadRelatedFileRef.clearFiles();
      // this.isOpenRelatedDialog = false;
      this.getCompanyRelatedList(res.data);
    },
    diffDays(date1, date2) {
      return diffDays(new Date(date1), new Date(date2));
    }
  }
}
</script>
